:root {
    --grid-gap-x: 10px;
    --grid-gap-y: 10px;
    --e-content-padding: 40px 8%;
}

* {
    margin: 0;
    padding: 0;
}

/* grid */
.flex-grid {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    width: 100%;
    margin: 0 auto;
  }
  
  .flex-grid > div {
    position: relative;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    box-sizing: border-box;
    margin: var(--grid-gap-y) var(--grid-gap-x);
  }
  
  .flex-grid.fixed-width {
    max-width: 1200px;
  }

  .flex-grid.no-space > div {
      margin: 0;
  }
  
  .col-12 {
    width: calc(100% - var(--grid-gap-x)*2);
  }

  .no-space > .col-12 {
    width: 100%;
  }
  
  .col-4 {
    width: calc(33.3333333333% - var(--grid-gap-x)*2);
  }

  .no-space > .col-4 {
    width: 33.3333333333%;
  }
  
  .col-5 {
    width: calc(41.6666666667% - var(--grid-gap-x)*2);
  }

  .no-space > .col-5 {
    width: 41.6666666667%;
  }
  
  .col-6 {
    width: calc(50% - var(--grid-gap-x)*2);
  }

  .no-space > .col-6 {
    width: 50%;
  }
  
  .col-7 {
    width: calc(58.3333333333% - var(--grid-gap-x)*2);
  }

  .no-space > .col-7 {
      width: 58.3333333333%;
  }
  
  .col-8 {
    width: calc(66.66666666666% - var(--grid-gap-x)*2);
  }

  .no-space > .col-8 {
    width: 66.66666666666%;
}
  
  .e-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  .e-content.x-start {
    align-items: flex-start;
  }

  .e-content.x-end {
    align-items: flex-end;
  }

  .e-content.y-start {
    justify-content: flex-start;
  }

  .e-content.y-end {
    justify-content: flex-end;
  }

  .e-content.e-content-padding {
    padding: var(--e-content-padding);
  }
  
  @media (max-width: 40em) {
    .flex-grid > div, .flex-grid.no-space > div {
      width: 100%;
    }
  }