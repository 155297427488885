:root {
  --main-c: #000;
  --bgr-c: #fff;
  --alt-bgr: rgb(247, 247, 247);
  --accent-c: #FCB040;
  --f1: 65px;
  --f2: 38px;
  --f3: 28px;
  --f4: 23px;
  --f5: 18px;
  --f6: 14px;
}

* {
  padding: 0;
  margin: 0;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: normal;
  font-weight: 300;
  line-height: 1.3em;
}

h1 {
  font-size: var(--f1);
  margin-bottom: .1em;
}

h2 {
  font-size: var(--f2);
  margin-bottom: 0.3em;
}

h3 {
  font-size: var(--f3);
}

p, a {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: var(--f4);
  font-weight: 300;
  line-height: 1.6em;
  margin: 10px 0;
}

a {
  text-decoration: none;
  color: var(--accent-c);
}

a.fill {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

button {
  background: none;
  outline: none;
  border: none;
  color: var(--accent-c);
  font-weight: bold;
  margin-bottom: 5px;
  cursor: pointer;
}

.subline {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: var(--f6);
  padding-top: 5px;
}

.image-container {
  width: 100%;
}

.image-container > img {
  width: 100%;
  display: block;
}

#preload {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background-color: var(--bgr-c);
  transform: translate3d(0,0,999px);
}

#preload.hide {
  opacity: 0;
  transition: opacity 500ms;
  pointer-events: none;
}

@media (max-width: 767.98px) {
  h1 {
    font-size: var(--f2);
  }

  h2 {
    font-size: var(--f3);
  }

  h3 {
    font-size: var(--f4);
  }

  p, a {
    font-size: var(--f5);
  }

  section {
    padding: 40px 0;
  }
}

/* cookie banner */
#cookies-eu-banner {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: var(--f5);
  position: fixed;
  left: 40px;
  bottom: 40px;
  width: 400px;
  padding: 15px 20px;
  box-sizing: border-box;
  z-index: 9998;
  background-color: var(--bgr-c);
  transform: translate3d(0,0,999px);
  -webkit-box-shadow: 0px 0px 30px -20px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 0px 30px -20px rgba(0,0,0,0.4);
  box-shadow: 0px 0px 30px -20px rgba(0,0,0,0.4);
}

#cookies-eu-banner * {
  font-size: var(--f5);
}

#cookies-eu-banner-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

#cookies-eu-banner-buttons {
  display: flex;
  flex-direction: row;
}

#cookies-eu-banner-buttons > button:first-child {
  margin-right: 10px;
}

@media (max-width: 767.98px) {
  #cookies-eu-banner {
    width: 100%;
    bottom: 0;
    left: 0;
  }

  #cookies-eu-banner *, #cookies-eu-banner-buttons {
    text-align: center;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
}

/* color and text-sizes */
.alt-bgr {
  background: var(--alt-bgr);
}

.accent-bgr {
  background: var(--accent-c);
}

.accent-bgr a {
  color: var(--bgr-c);
}

.f1 {
  font-size: var(--f1);
}

.f2 {
  font-size: var(--f2);
}

.f3 {
  font-size: var(--f3);
}

.f4 {
  font-size: var(--f4);
}

@media (max-width: 767.98px) {
  .f1 {
    font-size: var(--f2);
  }
  
  .f2 {
    font-size: var(--f3);
  }
  
  .f3 {
    font-size: var(--f4);
  }
  
  .f4 {
    font-size: var(--f5);
  }
}

/* logo */

#logo {
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: 900;
  font-size: var(--f1);
  padding: 10px 0 0 0;
  text-align: center;
  background: var(--bgr-c);
  z-index: 999;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  background: rgba(0,0,0,0);
}

#logo.straighten {
  transform-origin: left center;
  animation-name: straighten;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.47,2.64,.41,.8);
  animation-delay: 2s;
  transform: rotateZ(3deg);
}

@keyframes straighten {
  from {
    transform: rotateZ(3deg);
  }
  to {
    transform: rotateZ(0deg);
  }
}

#subtitle {
  margin-top: 15px;
}

#logo.sticky {
  position: fixed;
  top: 0;
  margin: 0 auto;
  left: 50%;
  transform: translate(0, -50%, 100px);
}

.fixed-header {
  position: fixed;
  width: 100%;
  top: 0;
  background: var(--bgr-c);
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  z-index: 999;
  transform: translate3d(0,0,999px);
  -webkit-box-shadow: 0px 10px 20px -20px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 10px 20px -20px rgba(0,0,0,0.4);
  box-shadow: 0px 10px 20px -20px rgba(0,0,0,0.4);
  padding: 15px 30px;
  box-sizing: border-box;
}

.header-logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.header-logo > p {
  font-size: var(--f3);
  font-weight: 900;
}

.header-meta > p, .header-meta > a {
  font-size: var(--f5);
}

#introduction {
  transition: all 0.35s ease;
  // animation-name: introduction;
  // animation-duration: 2s;
  position: absolute;
  top: 22%;
}

.intro-line {
  position: absolute;
  width: 5px;
  height: 25vh;
  top: 65vh;
  background: black;
  animation-name: introLine;
  animation-duration: 2s;
}

@keyframes introduction {
  from {
    opacity: 0;
    transform: translateY(70px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes introLine {
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 25vh;
  }
}

#introImage {
  width: 70%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%,0%);
  opacity: 0.9;
  animation-name: introductionImage;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes introductionImage {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.9;
  }
}

@media (max-width: 767.98px) {
  .fixed-header {
    padding: 0;
    justify-content: center;
  }

  .header-meta {
    display: none;
  }

  .header-logo {
    position: static;
    margin: 0;
    transform: none;
  }

  .header-logo p {
    margin: 0;
    padding: 0;
    font-size: var(--f4);
  }

  #subtitle > h3 {
    margin-top: 10px;
    font-size: var(--f5);
  }  
}

@media (max-width: 767.98px) {
  #logo {
    font-size: var(--f2);
  }
}

/* structure */
.fullscreen {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  flex-direction: column;
}

.vh-80 {
  width: 100%;
  min-height: 80vh;
  flex-direction: column;
}

.vh-40 {
  width: 100%;
  min-height: 40vh;
  flex-direction: column;
}

.flex-grid {
  padding: 40px 0;
}

.col-3 {
  width: calc(25% - var(--grid-gap-x)*2);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.stretch {
  justify-content: space-between;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.readmore {
  margin: 20px 0;
}

.text-wrapper {
  padding: 20px;
}

.buffer {
  height: 100px;
}

span.dot {
  color: var(--accent-c);
}

@media (max-width: 991.98px) { 
  .col-3.split-col-2 {
    width: calc(50% - var(--grid-gap-x)*2);
  }

  .flex-grid {
    padding: 0 15px;
    box-sizing: border-box;
  }

  .text-wrapper {
    padding: 0;
  }
}

/* custom elements */
.inflate-ball {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--accent-c);
}

.bullet-list ul {
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  margin: 40px 0;
}

.bullet-list li {
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: 900;
  font-size: var(--f4);
  margin: 40px 30px;
  flex-basis: auto;
  width: calc(33.3333333333% - 30px*2);
  list-style-position: inside;
  list-style: none;
  box-sizing: border-box;
  border: 6px solid var(--main-c);
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
}

// #introLinks {
//   position: absolute;
//   bottom: 0;
// }

#introLinks ul {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#introLinks li {
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: 900;
  font-size: var(--f4);
  margin: 40px 15px;
  list-style: none;
}

#introLinks li:after {
  position: absolute;
  bottom: calc(50% - 4px);
  content: "";
  width: 8px;
  height: 8px;
  background-color: var(--accent-c);
  margin-left: 10px;
}

#introLinks li:last-child::after {
  display: none;
}


.boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}

.box {
  box-sizing: border-box;
  background-color: var(--accent-c);
  height: calc((1200px - var(--grid-gap-x)*2) / 4);
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: 900;
  font-size: var(--f4);
}

.honeypot {
  position: absolute;
  left: -9999px;
}

.random-pos-container {
  position: relative;
  height: 700px;
  margin-bottom: 70px;
}

.random-pos {
  position: absolute;
}

@media (max-width: 1200px) {
  .box {
    height: calc((100vw - var(--grid-gap-x)*2) / 4);
    font-size: 2vw;
  }
}

@media (max-width: 991.98px) {
  .box {
    height: calc((100vw - var(--grid-gap-x)*4)/2);
  }
}

@media (max-width: 767.98px) {
  #introLinks ul {
    flex-direction: column;
    margin-top: 20px;
  }

  #introLinks li {
    margin: 0;
  }

  #introLinks li a {
    font-size: var(--f5);
  }

  #introLinks li:after {
    display: none;
  }

  .box {
    font-size: 3vw;
  }
}

/* accordion */
.acc-header {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: var(--f3);
  font-weight: 300;
  line-height: 1.4em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-right: 20px;
}

.acc-body {
  font-size: var(--f4);
  height: 0;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  -webkit-transition: height .2s ease-out, opacity .2s ease-out;
  -o-transition: height .2s ease-out, opacity .2s ease-out;
  transition: height .2s ease-out, opacity .2s ease-out;
}

.panel.active .acc-body {
  opacity: 1;
  height: auto;
  padding: 20px 15px;
  visibility: visible;
}

.panel {
  margin: 40px 0;
}

.arrow-down {
  position: relative;
  max-width: 40px;
  flex-shrink: 0;
}

@media (max-width: 767.98px) {
  .acc-header {
    font-size: var(--f5);
  }

  .arrow-down {
    max-width: 20px;
  }
}

/* references */
ul.references {
  display: flex;
  flex-direction: row;
  margin: 40px 0 0 0;
  padding: 0;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

ul.references > li {
  list-style: none;
  padding: 0;
  width: 150px;
  position: relative;
}

/* contact form */
.field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.field.inline {
  flex-direction: row;
  align-items: center;
  align-content: center;
}

form {
  margin: 40px 0;
}

label {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: var(--f4);
  font-weight: 300;
  margin: 12px 0 10px 0;
}

input[type=email], input[type=text], input[type=submit] {
  border: none;
  padding: 10px;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: var(--f4);
  font-weight: 300;
}

input[type=submit] {
  cursor: pointer;
  background-color: var(--bgr-c);
}

input[type=checkbox] {
  margin: 5px 10px 0 0;
  width: 20px;
  height: 20px;
}

textarea {
  border:  none;
  padding: 10px;
  height: 100px;
  resize: vertical;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: var(--f4);
  font-weight: 300;
}

input[type=email]:focus, input[type=text]:focus, input[type=submit]:focus, textarea:focus {
  outline: 3px solid var(--accent-c);
}

input[type=submit]:active {
  background-color: var(--accent-c);
}

@media (max-width: 767.98px) {
  label {
    font-size: var(--f5);
  }
}

/* footer */
footer {
  padding: 25px 0;
}

.footer-list > ul, .copyright-list > ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.footer-list > ul > li, .copyright-list > ul > li {
  list-style: none;
  margin: 0 20px;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: var(--f5);
  font-weight: 300;
}

.footer-list a {
  font-size: var(--f5);
  color: var(--main-c);
}

.copyright-list > ul {
  margin-top: 15px;
}

.copyright-list > ul > li {
  margin: 0 10px;
}

.copyright-list > ul > li a {
  font-size: var(--f6);
}

@media (max-width: 767.98px) { 
  .footer-list > ul, .copyright-list > ul {
    flex-direction: column;
  }

  .footer-list > ul > li {
    margin: 3px 0;
  }
}